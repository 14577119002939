import { ThemeUIStyleObject } from 'theme-ui';

export const PRODUCT_RATING_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingY: '56px',
  paddingX: ['22px', '80px'],
  margin: '0 auto',
  minWidth: '0px',
  maxWidth: '1520px',
  textAlign: 'center',
};

export const PRODUCT_RATING_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: ['column', 'column', 'column', 'row'],
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #DBDBDB66',
  background: 'white',
  borderRadius: '8px',
  boxShadow: '6px 9px 14px 4px rgba(136, 136, 136, 0.08)',
  gap: ['10px', '10px', '10px', '20px'],
  padding: '43px 20px',
  width: '100%',
};

export const ICON_STYLES: ThemeUIStyleObject = {
  height: '40px',
  width: '80px',
};

export const LINK_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    path: undefined,
    '&:hover': {
      path: undefined,
    },
  },
  '&:hover': {
    borderBottomColor: undefined,
  },
};

export const TITLE_STYLES: ThemeUIStyleObject = {
  lineHeight: '1.4',
  margin: '0px',
  fontWeight: '700',
  fontSize: '24px',
  textAlign: 'center',
};

export const PARA_STYLES: ThemeUIStyleObject = {
  fontSize: ['16px', '18px', '18px', '20px'],
};

export const RATING_BOLD_STYLES: ThemeUIStyleObject = {
  ...PARA_STYLES,
  fontWeight: 600,
};

export const RATING_TEXT_STYLES: ThemeUIStyleObject = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
};
