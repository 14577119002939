/** @jsx jsx */
import ReactTooltip from 'react-tooltip';
import { Box, Flex, jsx } from 'theme-ui';

import StyledLink from '@sprinklr/shared-lib/components/link';
import Rating from './components/Rating';
import { getRatingSchema } from './getRatingSchema';
import { HeadSchemaAdditions } from '@sprinklr/shared-lib';
import { Icon } from '@sprinklr/shared-lib/components/icon';

import { ProductRatingTemplateProps } from './types';

import {
  PRODUCT_RATING_CONTAINER_STYLES,
  PRODUCT_RATING_STYLES,
  ICON_STYLES,
  LINK_STYLES,
  TITLE_STYLES,
  PARA_STYLES,
  RATING_BOLD_STYLES,
  RATING_TEXT_STYLES,
} from './styles';

const ProductRatingTemplate = (props: ProductRatingTemplateProps) => {
  const {
    title,
    rating,
    numberOfReviews,
    ratingText,
    ratingProviderText,
    ratingTooltipText,
    icon,
    iconLink,
    includeAggregateRatingSchema,
  } = props;

  const ratingSchema = getRatingSchema(rating, numberOfReviews);
  return (
    <Box sx={PRODUCT_RATING_CONTAINER_STYLES}>
      {includeAggregateRatingSchema ? (
        <HeadSchemaAdditions schema={ratingSchema} />
      ) : null}
      <Box sx={PRODUCT_RATING_STYLES}>
        <Box as={'p'} sx={TITLE_STYLES}>
          {title}
        </Box>
        <Rating rating={rating} />
        <Flex sx={RATING_TEXT_STYLES}>
          <span sx={RATING_BOLD_STYLES}> {`${rating}/5`}</span>
          <Box data-tip data-for="ratingText" sx={PARA_STYLES}>
            {ratingText}
          </Box>
          <Box sx={PARA_STYLES}>{ratingProviderText}</Box>
          <ReactTooltip
            id="ratingText"
            place="bottom"
            backgroundColor="#FFFFFF"
            textColor="#000000"
            border={true}
            borderColor="#000000"
          >
            {ratingTooltipText}
          </ReactTooltip>
        </Flex>
        <StyledLink href={iconLink} styledLinkSx={LINK_STYLES}>
          <Icon iconName={icon.icon} iconSx={ICON_STYLES} />
        </StyledLink>
      </Box>
    </Box>
  );
};

export default ProductRatingTemplate;
