import React, { useMemo } from 'react';
import { Box, Flex, ThemeUICSSObject } from 'theme-ui';

import StarLogo from './StarLogo';
import { NUMBER_OF_STARS, RATING_PRECISION } from '../constants';

function getCoverWidth(rate) {
  const width = Math.round(
    ((NUMBER_OF_STARS * (1 / RATING_PRECISION) -
      Math.ceil(rate / RATING_PRECISION)) /
      (NUMBER_OF_STARS * (1 / RATING_PRECISION))) *
      100,
  );
  return width;
}

const RATING_CONTAINER_STYLES: ThemeUICSSObject = {
  display: 'inline-block',
  flexShrink: '0',
};

const RATING_STAR_STYLES: ThemeUICSSObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: '100%',
};

const RATING_STARS_COVER: ThemeUICSSObject = {
  position: 'absolute',
  height: '100%',
  backgroundColor: 'white',
  right: '0',
  top: '0',
  mixBlendMode: 'color',
};

const getRatingStarsCover = coverWidth => {
  return {
    ...RATING_STARS_COVER,
    width: `${coverWidth}%`,
  };
};

const Rating = ({ rating }) => {
  const ratingStarCoverStyles = useMemo(() => {
    const coverWidth = getCoverWidth(rating);
    return getRatingStarsCover(coverWidth);
  }, [rating]);

  return (
    <Box sx={RATING_CONTAINER_STYLES}>
      <Box sx={RATING_STAR_STYLES} as={'span'}>
        {[...Array(NUMBER_OF_STARS)].map((_, index) => {
          return (
            <Flex key={index} as="span">
              <StarLogo height="21px" width="26px" fill="gold" />
            </Flex>
          );
        })}
        <Box sx={ratingStarCoverStyles}></Box>
      </Box>
    </Box>
  );
};

export default Rating;
