import { useContext, useMemo } from 'react';
import { AggregateRatingSchema, RatingSchema } from './types';
import PageContext from '@sprinklr/shared-lib/context/pageContext';

const getAggregateRatingSchema = (
  rating: number,
  numOfReviews: number,
): AggregateRatingSchema => {
  return {
    '@type': 'AggregateRating',
    ratingValue: rating.toString(),
    ratingCount: numOfReviews.toString(),
  };
};

const getProductName = (slug: string) => {
  const slugWithoutTrailingSlash = slug?.endsWith('/')
    ? slug.slice(0, -1)
    : slug;
  const slugParts = slugWithoutTrailingSlash?.split('/');
  const productName = slugParts?.[slugParts.length - 1];
  return (
    productName?.charAt(0)?.toUpperCase() +
    productName?.slice(1)?.replace(/-/g, ' ')
  );
};

export const getRatingSchema = (
  rating: number,
  numOfReviews: number,
): RatingSchema => {
  const aggregateRatingSchema = getAggregateRatingSchema(rating, numOfReviews);
  const { slug } = useContext(PageContext);
  const productName = useMemo(() => getProductName(slug), [slug]);

  return {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: productName,
    applicationCategory: 'https://schema.org/BrowserApplication',
    aggregateRating: aggregateRatingSchema,
  };
};
